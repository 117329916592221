.event-header{
    position: relative;
    z-index: 4;
    width: 100vw;
    /* height: 100vh; */
}
.event-landing{
    padding:20px 100px 0 100px;
    position: relative;
    overflow-x: visible;
}
/* .event-landing::before{
    content: '';
    float: left;
    height: 100%;
}
.event-countdown-container::after{
    content: '';
    display: block;
    clear: both;
    height: 100%;
} */
.event-countdown-container{
    border-left: 5px solid #9126DE;
    position: relative;
    /* margin-top:100px; */
    overflow-x: visible;
}


.event-row-container{
    display: flex;
    padding: 50px 0;
    flex-direction: row;
    justify-content: flex-end;
}
.event-reg-btn-header{
    padding-left:135px;
    margin-top:20px;
    overflow-x: visible;
  
}
.header-text{
    width:auto;
    color: white;
    font-size:23px;
    font-weight: 400;
    padding-left: 135px;
    padding-right: 50px;
}
.counter{
    margin-left:100px;
    padding-top:90px;
}
.event-header-image{
    width: 80%;
    margin-left: 20%;
    /* width:397px; */
    /* height:397px; */
}
@media screen and (max-width: 1300px){
    .event-row-container{
       text-align: center;
    }
    .header-text{
        padding-left: 0px;
        padding-right:0px;
    }
    .event-header-image{
        display:none;
    }
    .event-reg-btn-header{
        padding-left: 0px;
    }
    .event-countdown-container{
        display:none;
    }
    .event-landing{
        padding-left: 20px;
        padding-right: 20px;
    }
    .counter{
        margin-left: auto;
    }
} 