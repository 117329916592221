* {
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    display: flex;
    margin: 2%;
    margin-left: 15%;
    margin-right: 15%;
    position: relative;
    height: 55px;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
    background: linear-gradient(180deg, #002874 0%, #CC0045 100%);
    box-shadow: rgba(65, 45, 45, 0.45) 0px 4px 6px -1px, rgba(0, 0, 0, 0.45) 0px 2px 4px -1px;
}

.nav-item {
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
    width: 100%;
}

.av-link {
    color: #fff;
    font-size: 14px;
    padding: 10px 35px;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    text-align: center;
}
.av-link:hover {
    font-weight: bold;
}
@media screen and (max-width:970px) {
    .navbar {
        margin-left: 10%;
        margin-right: 10%;
    }
}
@media screen and (max-width:840px) {
    .navbar {
        margin-left: 6%;
        margin-right: 6%;
    }
    .av-link {
        padding: 8px 10px;
        font-size: 14px;
    }
}
@media screen and (max-width:650px) {
    .av-link {
        padding: 8px 10px;
        font-size: 13px;
    }
}
@media screen and (max-width:450px) {
    
    .navbar {
        margin-left: 4%;
        margin-right: 4%;
    }
}
@media screen and (max-width:350px) {
    .av-link {
        padding: 8px 6px;
        font-size: 13px;
    }
    .navbar {
        height: 35px;
    }
}

@media screen and (max-width:300px) {
    .av-link {
        padding: 8px 3px;
        font-size: 10px;
    }
    .navbar {
        height: 30px;
    }
}