#prizes {
  position: relative;
  z-index: 4;
}

.prize-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  width: 98%;
  margin: auto;
  padding-right: 100px;
}

.prize-card {
  display: flex;
  flex-direction: column;
  background-color: #25043f;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 12px;
  padding: 2rem 4rem 2rem 4rem;
  color: white;
  margin: 2rem;
  margin-right: 2%;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 329px;
  width: 336px;
}
.prize-image {
  width: 13rem;
  height: auto;
}
.devfolio-prize-image {
  width: 180px;
}

.prize-text {
  font-size: large;
  padding-top: 10px;
  margin: auto;
  overflow: hidden;
}
@media only screen and (max-width: 850px) {
  .prize-card {
    width: 100%;

    margin: 1.5rem;
  }
  .prize-text {
    margin: auto;
    font-size: large;
  }
  .prize-container {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .prize-card {
    width: 100%;

    margin: 1.5rem;
    padding: 2rem;
  }
  .prize-image {
    width: 50%;
  }
  .prize-text {
    /* margin: auto; */
    /* font-size: large; */
    /* white-space: nowrap; */
  }
  .prize-container {
    padding-right: 0;
  }
}
