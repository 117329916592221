.angel-connect-investors {
  width: 100vw;
  /* height: calc(100vh + 320px); */
  height: auto;
  background-color: #cc0045;
  z-index: 3;
  position: relative;
}

.angel-investor-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  width: 90%;
}

.angel-investor-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 2rem;

  border-radius: 12px;
  padding: 10px;
  color: #002874;

  text-align: center;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 270px;
}
.angel-investor-image {
  text-align: center;
  object-fit: contain;
  height: 250px;
  width: 100%;
}

.angel-investor-text {
  padding: 15px 10px 15px 10px;
  margin: auto;
  overflow: hidden;
}
.angel-investor-heading {
  text-align: center;
  color: white;
  margin-top: 15rem;
}
@media only screen and (max-width: 850px) {
  .angel-investor-card-container {
    width: 50%;
  }
  .angel-investor-text {
    margin: auto;
  }
  .angel-connect-investors {
    width: auto;
    height: auto;
  }
  .angel-investor-heading {
    margin-top: 20rem;
  }
}

@media only screen and (max-width: 670px) {
  .angel-investor-card-container {
    width: 100%;
  }

  .angel-investor-card {
    margin: 2rem auto 2rem auto;
  }
  .angel-connect-investors {
    width: auto;
    height: auto;
  }
}
