#seeds-image {
  width: 15%;
  padding-bottom: 10px;
}

.seeds {
  width: 100%;
  min-height: 100vh;
  background: #002874;
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.section-heading-seeds {
  margin: 40px;
  font-weight: bold;
  font-size: 55px;
  line-height: 67px;
  text-transform: uppercase;
  color: white;
  position: relative;
}

.seeds-quote-container {
  width: 65%;
  align-self: center;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 3%;
  z-index: 100;
  position: relative;
  overflow: visible;
}

.seeds-quote-container::after {
  background-color: #002874;
  content: "”";
  display: block;
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 90px;
  text-align: center;
  padding: 0;
  color: rgba(255, 255, 255, 0.34);
  height: 35px;
  line-height: 80px;
  z-index: -200;
  position: absolute;
  width: 45px;
}
.seeds-quote-container::before {
  background-color: #002874;
  content: "“";
  display: block;
  font-family: Lemon;
  font-style: normal;
  font-weight: normal;
  font-size: 90px;
  text-align: center;
  padding: 0;
  color: rgba(255, 255, 255, 0.34);
  height: 50px;
  position: absolute;
  width: 50px;
  z-index: -200;
}
.seeds-quote-container::after {
  bottom: -15px;
  right: -10px;
}
.seeds-quote-container::before {
  top: -30px;
  left: -10px;
}

.seeds-quote-content {
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.72);
  
}

.seeds-button {
  font-size: 16.303px;
  line-height: 27px;
  text-transform: uppercase;
  color: white;
  padding: 7px 25px;
  width: 275px;
  text-align: center;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-decoration: none;
  align-self: center;
  background-color: #cc0045;
  font-weight: bold;
  z-index: 100;
}

.seeds-button:hover {
  background-color: #cc0045;
}
.seeds-content-container {
  width: 75%;
  align-self: center;
  position: relative;
  z-index: 5;
}

.seeds-content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 3%;
}
.seeds-content>a {
  color: #fff;
}

@media screen and (max-width: 790px) {
  .section-heading-seeds {
    font-size: 40px;
  }
  #seeds-image {
    width: 50%;
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  .seeds {
    align-items: center;
  }
}
