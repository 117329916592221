.angel-connect-faq {
  background-image: url("../../../assets/event_faq_background.png");
}

.angel-connect-faq-heading {
  text-align: center;
  color: white;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
