.workshops{
    width: 100%;
    min-height: 100vh;    
    background: #002874;
    padding: 40px;
    position: relative;
    z-index: 3;
}

.workshops-speaker-container {
    width: 100%;
    min-height: 100vh;    
    background: #002874;
    padding: 40px;
    position: relative;
    z-index: 3;
}

.section-heading-workshops{
    margin: 40px;
    font-weight: bold;
    font-size: 55px;
    line-height: 67px;
    text-transform: uppercase;
    color: white;
    position: relative;
}
#workshops-angel-connect-logo{
    width: 30%;
}
.workshops-container{
    /* margin: 70px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    justify-content: space-evenly;
    width: 100%;
    margin-top: 60px;
    position: relative;
}
.workshops-container-2{
    /* margin: 70px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    justify-content: space-evenly;
    background: #002874;
    width: 100%;
    margin-top: 60px;
    position: relative;
}

.workshops-box{
    height: 500px;
    width: 350px;
    margin-bottom: 20px;
    margin-right: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    position: relative;
    overflow:hidden;
}

.workshops-brewing-box{
    background: #BBBBBB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 500px;
    width: 350px;
    margin-bottom: 20px;
    margin-right: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    position: relative;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    color: #4B4B4B;
}
.workshops-image-text {
    /* display:none;    */
    transition: 0.2s ease;
    opacity: 0;
    position: absolute;
    top: 30%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* text-align: center; */
    font-size: 0.8rem;
    
}
.workshops-image-container{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: white;
    transition: 0.2s;
    overflow: hidden;
}

.workshops-text{
    padding-top:10px;
    position: absolute;
    bottom: 0;
    transform: translateY(0);
    height: 180px;
    width: 100%;
    background-color: white;
    font-weight: 600;
    font-size: 29.7384px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
} 
.workshops-image{
    width: 100%;
    height: 320px;
    transition: 0.2s ease;
}

.workshops-text-name-container-hackathon{
    overflow: hidden;
    padding-top:5px;
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 20px;
}
.workshops-subtext-name-container-hackathon{
    overflow: hidden;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 15px;
    color: #4B4B4B;
}
.workshops-designation-name-container-hackathon{
    overflow: hidden;
    padding-top: 5px;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
}

.workshops-text-name-container-angelconnect{
    overflow: hidden;
    padding-top:1px;
    /* padding-bottom: 5px; */
    font-size: 24px;
    line-height: 20px;
}
.workshops-text-name-image{
    width: 80%;
}
.workshops-text-designation{
    font-size: 16px;

    line-height: 25px;
}

.workshops-button{
    font-weight: 300;
font-size: 16.303px;
line-height: 27px;
text-transform: uppercase;
color: white;
margin-top: 10px;
padding: 7px 25px;
width: 80%;
text-align: center;
box-shadow: 0px 2.78788px 2.78788px rgba(0, 0, 0, 0.25);
border-radius: 20px;
text-decoration: none;
background-color: #002874;
transition: 0.3s;
position: absolute;
margin-bottom: 20px;
bottom: 2px;
}

#angelconnet-image-banner {
    width: 100%;
    height: 65%;
}

.workshops-button:hover{
    background-color: #CC0045;
}

.blue{
    background-color: #002874;
}

.red{
    background-color: #CC0045;
}

.mymodal{
    z-index: 1000;
    background-color: #fff;
    position: fixed;
  top: 50%;
  left: 50%;
  height: 70%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
    
}
@media screen and (max-width:1100px){
    .workshops-box{
        height: 370px;
        width: 240px;
    }
    .workshops-brewing-box{
        height: 370px;
        width: 240px;
    }
}


@media screen and (max-width:934px){
    .workshops-box{
        height: 400px;
        width: 300px;
    }
    .workshops-brewing-box{
        height: 400px;
        width: 300px;
    }
}

@media screen and (max-width:790px){
    .workshops-box{
        height: 340px;
        width: 220px;
    }
    .section-heading-workshops {
        font-size: 40px;
    }
    .workshops-brewing-box{
        height: 340px;
        width: 220px;
    }
    .workshops-designation-name-container-hackathon{
        font-size: 11px;
    }
}

@media screen and (max-width:616px){
    .workshops-box{
        height: 370px;
        width: 270px;
    }
    .workshops-brewing-box{
        height: 370px;
        width: 270px;
    }
}

@media screen and (max-width:500px){
    .workshops{
        
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .workshops-container{
        align-items: center;
        justify-content: center;
    }
    .workshops-box{
        height: 370px;
        width: 270px;
        margin-right: 0;
    }
    .workshops-brewing-box{
        height: 370px;
        width: 270px;
        margin-right: 0;
    }
    .workshops-container-2{
        margin-top: 0;
    }
    
    .section-heading-workshops{
        font-size: 22px;
        z-index: 1;
        text-align: center;
    }
}