#about{
    position: relative;
}
.about-heading{
    color: #002874;
    padding: 25px 50px;
    margin-top: 25px;
    position: relative;
    z-index: 3;
}
.about-text{
    display: flex;
    position: block;
    color: #002874;
    justify-content: center;
    text-align: justify;
    padding: 40px 0;
}
.clg-img{
    width: 55%;
    height: 60%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0 20px;
}
.box1{
    width: 85%;
    display: flex;
    box-sizing: content-box;
    box-shadow: 5px 10px 30px 10px rgba(0, 0, 0, 0.06);
    border-radius: 0 15px 15px 0;
    /* box-shadow: 2px 3px 8px gray; */
    padding: 10px 30px;
    margin-bottom: 50px;
    background-color: white;
    position: relative;
    z-index: 3;
}
.box2{
    width: 90%;
    display: flex;
    box-sizing: content-box;
    border-radius: 15px 0 0 15px;
    box-shadow: 5px 10px 30px 10px rgba(0, 0, 0, 0.06);
    padding: 0 20px;
    margin-bottom: 50px;
    float: right;
    background-color: white;
    position: relative;
    z-index: 3;
}
.about-text.iedc{
    padding-right: 15px;
}
.iedc-img{
    width: 40%;
    height: 100%;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 20px;
}
.tie-img{
    width: 30%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 20px;
}
.about-text2{
    color: #CC0045;
    text-align: justify;
    padding: 40px 0;
    padding-right: 15px;
}
.about-text.techno{
    padding-left: 30px;
}
.ksum{
    height: 20%;
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 20px;
}
/* */

@media screen and (max-width:500px){
    .box1,.box2,.iedc-img,.tie-img,.ksum{
        /* border-radius: 15px; */
        margin-left: auto;
        margin-right: auto;
    }
    .box1{
        flex-direction: column-reverse;
        width: 75%;
    }
    .box2{
        flex-direction: column;
        width: 80%;
        float: none;
    }
    .clg-img{
        margin-right: auto;
        width: 65%;
    }
    .iedc-img{
        margin-left: auto;
        width: 50%;
        padding-top: 30px;
    }
    .tie-img{
        width: 25%;
        padding-top: 10px;
    }
    .ksum{
        flex-direction: column;
    }
}