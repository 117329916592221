

@media only screen and (min-width: 1024px) {
    .footer-container{
        position: relative;
        width:100vw;
        height: 200px;
        background: #F9F9F9;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
    }
    
    .footer-iedc-logo{
        position: relative;
        width: 220px;
        top: 77px;
        margin-left: 4.5%;
         
    }
    
    .footer-copy{
        display: flex;
        flex-direction: column;
        position: relative;
        width: 765px;
        height: 164px;
        left: 10px;
        top: 30px;
        line-height: 200%;
    /* or 50px */
    
    font-feature-settings: 'kern' off;
    
    color: #002874;
    
    }
    
    .footer-h4{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
        overflow: hidden;
    }
    
    .footer-address{
        font-family: Montserrat;
        font-style: normal;
        font-size: 18px;
        overflow: hidden;
    }
    
    .footer-social{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-around;
        width: 278.2px;
        /* left: -25px; */
        top: 71px;
    }

    .footer-insta-image{
        width: 80%;
    }
    .footer-linkedin-image{
        width: 80%;
    }
    .footer-twitter-image{
        margin-top: 5px;
        width: 80%;
    } 

}

@media only screen and (max-width: 1023px) {
    .footer-container{
        position: relative;
        width:100vw;
        height: 200px;
        background: #F9F9F9;
        bottom: 0;
        display: flex;
        justify-content: space-between;
    }
    
    .footer-iedc-logo{
        position: relative;
        width: 220px;
        height: 46px;

        top: 77px;
        margin-left: 4.5%;
         
    }
    .footer-iedc-image
    {
        width:132px;
        height: 27.6px;
    }
    .footer-copy{
        display: flex;
        flex-direction: column;
        position: relative;
        width: 765px;
        height: 164px;
        left: 20px;
        top: 30px;
        line-height: 150%;

    
    font-feature-settings: 'kern' off;
    
    color: #002874;
    
    }
    
    .footer-h4{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
        
    }
    
    .footer-address{
        font-family: Montserrat;
        font-style: normal;
        font-size: 15px;
    }
    
    .footer-social{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-around;
        width: 150px;
        height: 57.66px;
        left: -30px;
        top: 71px;
    }

    .footer-insta-image{
        width: 32px;
    }
    .footer-linkedin-image{
        width: 32px;
        margin-left: 9px;
    }
    .footer-twitter-image{
        position: relative;
        width: 32px;
        height: 32px;
        margin-left: 10px;

    } 
}

@media only screen and (max-width: 924px) {
    .footer-container{
        display: flex;
        flex-direction: column;
        background: #F9F9F9;
        position: relative;
        height: 300px;
        justify-content: center;
        align-items: center;
        bottom: 0;
        text-align: center;
    }
    
    
    .footer-iedc-logo{
        position: relative;
        width: 220px;
        height: 46px;
        left: -17px;
        top: 5px;
     
    }
    .footer-iedc-image
    {
        width:132px;
        height: 27.6px;
    }
    .footer-copy{
        display: flex;
        flex-direction: column;
        position: relative;
        width: 400px;
        height: 140px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        font-feature-settings: 'kern' off;
        color: #002874;
        margin-left: 10px;
        margin-right: 50px;
        top: 10px
    
    }
    
    .footer-h4{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 5px;
        
    }
    
    .footer-address{
        font-family: Montserrat;
        font-style: normal;
        font-size: 15px;
    }
    
    .footer-social {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 150px;
        height: 57.66px;
        left:0%;
        top:20px
    }

    .footer-insta-image{
        width: 40px;
    }
    .footer-linkedin-image{
        width: 40px;
        margin-left: 15px;
    }
    .footer-twitter-image{
        margin-top: 5px;
        margin-left: 15px;
        /* position: relative; */
        width: 40px;
        /* height: 32px; */

    } 
}
