.home-faq {
  background-color: #cc0045;
}
.faq-title {
  margin: 40px;
  margin-bottom: 20px;
  padding-left: 40px;
  font-weight: bold;
  font-size: 50px;
  color: white;
  position: relative;
}

@media only screen and (max-width: 500px) {
  .faq-title {
    text-align: center;
    font-size: 30px;
    padding: 0;
  }
}
