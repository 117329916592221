@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --light: #9126DE;
  --eventFooterBg: #27054D;
}

.App {
  text-align: center;
}

*{
  font-family: Montserrat;
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.section-heading{
  margin: 40px;
  font-weight: bold;
  font-size: 55px;
  line-height: 67px;
  text-transform: uppercase;
}

html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}