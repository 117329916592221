#sponsors{
    position: relative;
    z-index: 4;
}
.sponsors-container {
    color: white;
    margin-top: 20px;
    margin-left: 140px;
}

.sponsors-title {
    margin-top: 50px;
    font-weight: 420;
    font-size: 25px;
}

.sponsors-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
    margin-top: 20px;
}

.sponsors-col {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    border-radius: 15px;
    border: 4px solid #9126DE;
    margin-bottom: 40px;
}
.sponsor-image{
    width: 150px;
}
.sponsors-col-lg {
    width: 24%;
    margin-right: 40px;
}

.sponsors-col-sm {
    width: 20%;
    margin-right: 30px; 
} 

.sponsors-heading-diamond {
    color: #A2CEE4 ;
}

.sponsors-heading-platinum{
    color: #48B1E5
}

.sponsors-heading-gold {
    color: #E2A548;
}

.sponsors-heading-bronze {
    color: #E48D11;
}

@media (max-width: 1200px) {
    .sponsors-col {
        height: 160px;
        border: 4px solid #9126DE;
        margin-bottom: 12px;
    }
    .sponsors-col-lg {
        width: 36%;
        margin-right: 12px;
    }
    .sponsors-col-sm {
        width: 36%;
        margin-right: 12px; 
    }  
}

@media (max-width: 900px) {
    .sponsors-container {
        margin-left: 10px;
    }
    .sponsors-col {
        height: 130px;
        border: 3px solid #9126DE;
        margin-bottom: 12px;
        display: flex;
        justify-items: center;
    }
    .sponsor-image{
        width:70%;
        margin-left: 10%;
    }
}