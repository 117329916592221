.event-page{
    background: #19072C;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    overflow-x: hidden;
    overflow-y: hidden !important;
    height: fit-content;
}
.event-background-container{
    position: relative;
}
.event-main{
    /* margin-top: -200px; */
    margin-left: 100px;
    border-left: 5px solid #9126DE;
    width: 100vw;
    padding-top:100px;
    padding-bottom: 100px;
    position: relative;
    z-index: 4;
    overflow-x: visible;
}
.left-border-top-square{
    position: absolute;
    height: 15px;
    width: 15px;
    top: 50px;
    left: -10px;
    background: #9126DE;
}
@media (min-height: 850px) {
    .event-main {
        margin-top: -350px;
    }
}

@media (min-width: 450px) {
    .event-main {
        margin-top: -50px;
    }
}


@media (max-width: 480px) {
    .event-main {
        margin-top: -100px;
    }
}


@media (max-width: 900px) {
    .event-main{
        margin-left: 0;
        border-left: hidden;
    }
}