/* .angel-connect-footer{
    width: 100vw;
    position: relative;
    height: 200px;
    background: #27054D;
} */

.angel-connect-footer {
    position: relative;
    background-color: #CC0045;
    color: white;
    line-height: 1.5rem;
    z-index: 4;
  }
  
  .angel-connect-footer .container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;
  }
  
  .angel-connect-footer .socials img {
    margin-left: 1rem;
    height: 2rem;
  }
  
  @media screen and (min-width: 1080px) {
    .angel-connect-footer-margin {
      position: absolute;
      top: 0;
      left: 0;
      height: 9rem;
      width: 105px;
      border-right: 5px solid #9126de;
      overflow-x: visible;
    }
  
    .angel-connect-footer-margin::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: -10px;
      width: 15px;
      height: 15px;
      background-color: #9126de;
    }
  
    .angel-connect-footer .container {
      padding: 6rem 9rem;
      align-items: center;
    }
  
    .angel-connect-footer .container > div {
      flex-grow: 1;
      padding: 0 1rem;
    }
  
    .angel-connect-footer .description-container {
      align-self: center;
      flex-grow: 2;
    }
  
    .angel-connect-footer .description {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  
    .angel-connect-footer .socials {
      display: flex;
    }
  
    .angel-connect-footer .socials a:first-child {
      margin-left: auto;
    }
  
    .angel-connect-footer .socials a {
      margin-left: 1.5rem;
    }
  }
  
  @media screen and (max-width: 1080px) {
    .angel-connect-footer .container {
      padding: 3rem 2rem;
      flex-direction: column;
      width: 100%;
    }
  
    .angel-connect-footer .logo {
      margin-bottom: 1rem;
    }
  
    .angel-connect-footer .socials {
      display: flex;
      justify-content: center;
    }
  
    .angel-connect-footer .socials a {
      margin-top: 3rem;
      margin-left: 1rem;
      margin-right: 1rem;
      height: 2rem;
      display: block;
    }
  }
  